

































import {
  defineComponent,
  onMounted,
  reactive,
  ref,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { v4 as uuid } from "uuid";

export default defineComponent({
  components: {
    MBasicCriteria: () =>
      import(
        "@/components/molecules/abstracts/advancedSearch/m-basic-criteria.vue"
      ),
    MOtherCriteria: () =>
      import(
        "@/components/molecules/abstracts/advancedSearch/m-other-criteria.vue"
      ),
    MSearchResult: () =>
      import(
        "@/components/molecules/abstracts/advancedSearch/m-search-result.vue"
      ),
  },
  setup(_, { root }) {
    const form = ref<any>(null);

    const model: any = reactive({
      basicData: {
        abstractType: {
          entity: "abstract",
          fieldName: "type",
          values: [],
          operation: "in",
        },
        sessionTopic: {
          entity: "sessionTopic",
          fieldName: "name",
          values: [],
          operation: "is",
        },
        abstractTitle: {
          entity: "abstract",
          fieldName: "title",
          values: [],
          operation: "is",
        },
        abstractShort: {
          entity: "abstract",
          fieldName: "short",
          values: [],
          operation: "is",
        },
        abstractNumber: {
          entity: "abstract",
          fieldName: "index",
          values: [],
          operation: "equal",
        },
        abstractRating: {
          entity: "abstract",
          fieldName: "totalAverage",
          values: [],
          operation: "between",
        },
        senderFirstName: {
          entity: "participant",
          fieldName: "firstName",
          values: "",
          operation: "is",
        },
        senderLastName: {
          entity: "participant",
          fieldName: "lastName",
          values: "",
          operation: "is",
        },
        authorFirstName: {
          entity: "author",
          fieldName: "firstName",
          values: "",
          operation: "is",
        },
        authorLastName: {
          entity: "author",
          fieldName: "firstName",
          values: "",
          operation: "is",
        },
        abstractCreatedAt: {
          entity: "abstract",
          fieldName: "createdAt",
          values: [],
          operation: "between",
        },
        abstractUpdateddAt: {
          entity: "abstract",
          fieldName: "updatedAt",
          values: [],
          operation: "between",
        },
      } as any,
      otherCriteria: {
        designatedReviewer: {
          entity: "reviewers",
          fieldName: "id",
          values: [],
          operation: "isNull",
        },
        notDesignatedReviewer: {
          entity: "reviewers",
          fieldName: "id",
          values: [],
          operation: "isNotNull",
        },
        fullyReviewed: {
          entity: "null",
          fieldName: "null",
          values: [],
          operation: "relationsCountsNotEqual",
        },
        notFullyReviewed: {
          entity: "null",
          fieldName: "null",
          values: [],
          operation: "countGreaterThanZero",
        },
        abstractStatus: {
          entity: "abstract",
          fieldName: "status",
          values: [],
          operation: "in",
        },
      },
    });

    const state = reactive({
      loading: false,
      error: false as boolean | number,
      expanded: 0,
      searchResults: [],
      searchCriteria: [] as any,
      dataSearch: [] as any,
      otherSearch: [] as any,
      renderId: "",
      topics: [],
    });

    const fetchTopics = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`event/${root.$route.params.id}/session-topic`)
        .then(({ data: { sessionTopic } }) => {
          state.topics = sessionTopic.filter((el: any) => el.isActive);
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.topics = [];
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loading = false;
        });
    };

    onMounted(fetchTopics);

    watch(
      () => model.basicData,
      () => {
        const tempArray: any = Object.keys(model.basicData as any).map(
          (e) => model.basicData[e]
        );
        state.dataSearch = tempArray.filter(
          (el: any) => el.values && el.values.length
        );
      },
      { deep: true }
    );
    watch(
      () => model.otherCriteria,
      () => {
        const tempArray: any = Object.keys(model.otherCriteria as any).map(
          (e) => model.otherCriteria[e]
        );
        state.otherSearch = tempArray.filter(
          (el: any) => el.values && el.values.length
        );
      },
      { deep: true }
    );

    watch(
      () => state.searchResults,
      () => {
        if (state.searchResults && state.searchResults.length > 0) {
          state.expanded = 1;
        }
      },
      { deep: true }
    );

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 400:
          return `${root.$tc("layout.errors.400")}`;
        case 401:
          return `${root.$tc("layout.errors.401")}`;
        case 404:
          return `${root.$tc("panel.event.abstract.search.notFound")}`;
        case 500:
          return `${root.$tc("layout.errors.500")}`;
        default:
          return `${root.$tc("layout.errors.default")}`;
      }
    };

    const onSubmit = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      const data = {
        criteria: state.searchCriteria.concat(
          state.dataSearch,
          state.otherSearch
        ),
      };

      state.loading = true;

      axiosInstance
        .post(`event/${root.$route.params.id}/abstract/advanced-search`, data, {
          headers: {
            event: root.$route.params.id,
          },
        })
        .then(({ data }) => {
          state.searchResults = data;
          state.renderId = uuid();
          if (state.searchResults.length < 1) {
            root.$store.commit("snackbar/PUSH_MESSAGE", {
              id: uuid(),
              color: "error",
              message: root.$tc("panel.event.abstract.search.notFound"),
            });
          }
          state.error = false;
        })
        .catch((error) => {
          state.error = error.response.status;
          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "error",
            message: getErrorMessage(state.error as number),
          });
        })
        .finally(() => (state.loading = false));
    };

    const clearForm = () => {
      form.value.reset();
    };

    return {
      form,
      model,
      state,
      onSubmit,
      clearForm,
    };
  },
});
